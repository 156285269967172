const apiUrl = "https://n1-wallet-node.azurefd.net"

const infuraUrl = "https://kovan.infura.io/v3/075662b6bb6c405d8f8fe7715071e065"

export const getPoaWalletNonce = (walletAddress, authorizedAddress) => {
    return fetch(
        `${apiUrl}/poa/api/wallet/nonce`,{
            method: 'POST',
            body: JSON.stringify({
                walletAddress:walletAddress,
                authorizedAddress:authorizedAddress
            }),
            headers: new Headers({
            'Content-Type': 'application/json'
            })
        }
    )
        .then((response) => response.json())
        .then((data)=>data.data)
        .then((data) => {
            return data
        });
};

export const getSideChainEthNonce = (address) => {
    return fetch(
        `${apiUrl}/poa/api/eth/nonce`,{
            method: 'POST',
            body: JSON.stringify({
                ethAddress:address,
            }),
            headers: new Headers({
            'Content-Type': 'application/json'
            })
        }
    )
        .then((response) => response.json())
        .then((data)=>data.data)
        .then((data) => {
            return data
        });
};

export const getEthWalletNonce = (contractAddress, signerAddress) => {
    return fetch(
        infuraUrl,{
            method: 'POST',
            body: JSON.stringify({
                id: 1,
                jsonrpc: '2.0',
                method: 'eth_call',
                params: [{
                    data: "0x6e05dc11000000000000000000000000"+signerAddress.toLowerCase().substr(2),
                    to:contractAddress.toLowerCase(),
                }, "latest"]
            }),
            headers: new Headers({
            'Content-Type': 'application/json'
            })
        }
    )
        .then((response) => response.json())
        .then((data)=>data.result)
        .then((data) => {
            return data
        });
};