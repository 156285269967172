import React, { useEffect } from 'react';
import Game from './Game';

const Main = () => {

  useEffect(
    () => {
    },[]
  )

  return (
    <React.Fragment>
      <Game/>
    </React.Fragment>
  );
}

export default Main;
